<template>
  <div class="add_acc">
    <div>
      <el-form label-position="top" label-width="auto">
        <el-form-item :label="$t('main.text16')" class="is-required">
          <el-input
            v-model.lazy="form.payee_country"
            :placeholder="$t('main.text75')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('main.text17')" class="is-required">
          <el-input
            v-model.lazy="form.payee_bank_name"
            :placeholder="$t('main.text75')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('main.text89')" class="is-required">
          <el-input
            v-model.lazy="form.payee_last_name"
            :placeholder="$t('main.text75')"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('main.text90')" class="is-required">
          <el-input
            v-model.lazy="form.payee_account_name"
            :placeholder="$t('main.text75')"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('main.text19')" class="is-required">
          <el-input
            v-model.lazy="form.payee_card_num"
            :placeholder="$t('main.text75')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('main.text20')">
          <el-input
            v-model.lazy="form.payee_swift_code"
            :placeholder="$t('main.text75')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div
      class="btm_btn btn_ok"
      v-if="
        form.payee_country &&
        form.payee_bank_name &&
        form.payee_last_name  &&
        form.payee_account_name &&
        form.payee_card_num
      "
      @click="save_btn"
    >
      {{ $t("main.text77") }}
    </div>
    <div class="btm_btn btn_no" v-else>{{ $t("main.text77") }}</div>
  </div>
</template>

<script>
import { payee_add, payee_edit ,getKycInfo } from "../../../api/api";
export default {
  data() {
    return {
      form: {
        // payee_type
        payee_country: "",
        payee_bank_name: "",
        payee_last_name: "",
        payee_account_name: "",
        payee_card_num: "",
        payee_swift_code: "",
        // payee_addr
      },
      edit_data: {},
      is_edit: this.$route.query.is_edit,
    };
  },
  beforeMount() {
    if (this.is_edit) {
      sessionStorage.setItem("hichat_title", this.$t("main.text78"));
      let edit_data = JSON.parse(sessionStorage.getItem("edit_data"));
      this.form.payee_country = edit_data.payee_country;
      this.form.payee_bank_name = edit_data.payee_bank_name;
      this.form.payee_last_name = edit_data.payee_last_name;
      this.form.payee_account_name = edit_data.payee_account_name;
      this.form.payee_card_num = edit_data.payee_card_num;
      this.form.payee_swift_code = edit_data.payee_swift_code;
      this.edit_data = edit_data;
    } else {
      sessionStorage.setItem("hichat_title", this.$t("main.text79"));
    }
  },
  mounted() {
    this.get_kyc_info();
  },
  methods: {
    setStor() {
      let obj = {
        payee_country: this.form.payee_country,
        payee_bank_name: this.form.payee_bank_name,
        payee_last_name: this.form.payee_last_name,
        payee_account_name: this.form.payee_account_name,
        payee_card_num: this.form.payee_card_num,
        payee_swift_code: this.form.payee_swift_code,
        tx_id: this.edit_data.tx_id,
      };
      sessionStorage.setItem("edit_data", JSON.stringify(obj));
    },
    async save_btn() {
      if (!this.is_edit) {
        const res = await payee_add({
          payee_type: 1,
          payee_country: this.form.payee_country,
          payee_bank_name: this.form.payee_bank_name,
          payee_last_name: this.form.payee_last_name,
          payee_account_name: this.form.payee_account_name,
          payee_card_num: this.form.payee_card_num,
          payee_swift_code: this.form.payee_swift_code,
        });
        if (res.data.code !== 200) {
          return this.msg("error", res.data.msg);
        }
        this.setStor();
        this.msg("success", this.$t("main.text10"));
        this.$router.push({ path: "set/set_account" });
      } else {
        const res = await payee_edit({
          tx_id: this.edit_data.tx_id,
          payee_type: 1,
          payee_country: this.form.payee_country,
          payee_bank_name: this.form.payee_bank_name,
          payee_last_name: this.form.payee_last_name,
          payee_account_name: this.form.payee_account_name,
          payee_card_num: this.form.payee_card_num,
          payee_swift_code: this.form.payee_swift_code,
        });
        if (res.data.code !== 200) {
          return this.msg("error", res.data.msg);
        }
        this.setStor();
        this.msg("success", this.$t("main.text10"));
        this.$router.go(-1);
      }
    },
    async get_kyc_info(){
      const res = await getKycInfo();
      if (res.data.code !== 200) {
        return this.msg("error", res.data.msg);
      }
      this.form.payee_last_name = res.data.data.lastName;
      this.form.payee_account_name = res.data.data.firstName;
    }
  },
};
</script>

<style scoped>
.add_acc {
  box-sizing: border-box;
  padding: 1.25rem;
  position: relative;
}
.add_acc >>> .el-form-item__label {
  line-height: 17px;
  padding-bottom: 0.5rem;
}
.btm_btn {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
.add_acc >>> .el-input.is-disabled .el-input__inner {
  color: #606266;
}

</style>

