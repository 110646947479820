var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add_acc"},[_c('div',[_c('el-form',{attrs:{"label-position":"top","label-width":"auto"}},[_c('el-form-item',{staticClass:"is-required",attrs:{"label":_vm.$t('main.text16')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main.text75')},model:{value:(_vm.form.payee_country),callback:function ($$v) {_vm.$set(_vm.form, "payee_country", $$v)},expression:"form.payee_country"}})],1),_c('el-form-item',{staticClass:"is-required",attrs:{"label":_vm.$t('main.text17')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main.text75')},model:{value:(_vm.form.payee_bank_name),callback:function ($$v) {_vm.$set(_vm.form, "payee_bank_name", $$v)},expression:"form.payee_bank_name"}})],1),_c('el-form-item',{staticClass:"is-required",attrs:{"label":_vm.$t('main.text89')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main.text75'),"disabled":""},model:{value:(_vm.form.payee_last_name),callback:function ($$v) {_vm.$set(_vm.form, "payee_last_name", $$v)},expression:"form.payee_last_name"}})],1),_c('el-form-item',{staticClass:"is-required",attrs:{"label":_vm.$t('main.text90')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main.text75'),"disabled":""},model:{value:(_vm.form.payee_account_name),callback:function ($$v) {_vm.$set(_vm.form, "payee_account_name", $$v)},expression:"form.payee_account_name"}})],1),_c('el-form-item',{staticClass:"is-required",attrs:{"label":_vm.$t('main.text19')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main.text75')},model:{value:(_vm.form.payee_card_num),callback:function ($$v) {_vm.$set(_vm.form, "payee_card_num", $$v)},expression:"form.payee_card_num"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('main.text20')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('main.text75')},model:{value:(_vm.form.payee_swift_code),callback:function ($$v) {_vm.$set(_vm.form, "payee_swift_code", $$v)},expression:"form.payee_swift_code"}})],1)],1)],1),(
      _vm.form.payee_country &&
      _vm.form.payee_bank_name &&
      _vm.form.payee_last_name  &&
      _vm.form.payee_account_name &&
      _vm.form.payee_card_num
    )?_c('div',{staticClass:"btm_btn btn_ok",on:{"click":_vm.save_btn}},[_vm._v(" "+_vm._s(_vm.$t("main.text77"))+" ")]):_c('div',{staticClass:"btm_btn btn_no"},[_vm._v(_vm._s(_vm.$t("main.text77")))])])
}
var staticRenderFns = []

export { render, staticRenderFns }